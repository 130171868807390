@use "./assets/styles/base";

//Components

// clientChat scss

@use "../src/components/ClientChat";

// RatingModal

@use "../src/components/RatingModal";

// Employee scss

@use "../src/components/Employee";

// toolTip

@use "../src/components/ToolTip";

// Upload

@use "../src/components/Upload";

// ImagePopup

@use "../src/components/ImagePopup";

// Message

@use "../src/components/Messages";

// Audio Recorder

@use "./components/AudioRecorder";


.poste-history-element{
    display:flex;
    justify-content: center;
    align-items: center;
    gap:10px;
}
.history{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
}